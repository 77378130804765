import Vue from "vue";
import VueRouter from "vue-router";
import Layout from '@/components/layout/PageLayout.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    component: Layout,
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import('@/views/PageHome.vue'),
      },
      {
        path: 'video_gen',
        name: 'VideoGen',
        component: () => import('@/views/VideoGen.vue')
      }
    ]
  },
  {
    path: "/changepwd",
    name: "Changepwd",
    component: () => import('@/views/PageChangePwd.vue'),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import('@/views/PageLogin.vue'),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import('@/views/PageRegister.vue'),
  }
];

const router = new VueRouter({
  routes,
  // mode: 'history',
});

export default router;
