
export function isMobile() {   //判断是否为移动端
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile/.test(userAgent);
}
export function validatePhone(rule, value, callback) {
  const reg = /^1[3-9]\d{9}$/;
  if (value == '' || value == undefined || value == null) {
    callback();
  } else {
    if ((!reg.test(value)) && value != '') {
      callback(new Error('请输入正确的电话号码'));
    } else {
      callback();
    }
  }
}
